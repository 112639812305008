




























import Vue from 'vue';
import loadingUI from '../../../components/loading.vue';
// import VueNumber from 'vue-number-animation';
// Vue.use(VueNumber);

export default Vue.extend({
  name: 'runUploadUI',
  components: {loadingUI},
  props: {
    showFileScan: {
      type: Boolean,
      required: true,
    },
    showUpload: {
      type: Boolean,
      required: true,
    },
    showNoUpload: {
      type: Boolean,
      required: true,
    },
    // showComplete: {
    //   type: Boolean,
    //   required: true,
    // },
    totalRunUploadCount: {
      type: Number,
      required: true,
      default: 0,
    },
    totalCompletedRunUploadCount: {
      type: Number,
      required: true,
    },
    totalCompletedSnapshot: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showCompleted: false,
      hideCompleted: false,
      showUploadTrueCompletedRunUploadCount: 0,
      loadbarDelay: false as boolean,
      loadBarShowTimestamp: 0 as number,
      recompute: 0,
    };
  },
  watch: {
    // showUploadTrueCompletedRunUploadCount() {
    //   // const nowTrue = this.showUpload;
    //   this.storeCompetedRunUploadCountAtThisTime();
    // },
    showUpload() {
      this.storeCompetedRunUploadCountAtThisTime();
      this.loadBarShowTimestamp = Date.now();
    },
    showFileScan() {
      this.showCompleted = false;
      this.hideCompleted = false;
      this.storeCompetedRunUploadCountAtThisTime();
      this.loadBarShowTimestamp = Date.now();
    },
  },
  computed: {
    fromShowingTotalRunUploadCount(): number {
      // this.countUpAccepted('accepted', this.filesAccepted);
      return this.totalRunUploadCount - this.totalCompletedSnapshot;
    },
    fromShowingTotalCompletedRunUploadCount(): number {
      // this.countUpAccepted('accepted', this.filesAccepted);
      return this.totalCompletedRunUploadCount - this.totalCompletedSnapshot;
    },
    percentUploadComplete(): number {
      this.recompute;
      const delayTimeInMs = 350;

      if (this.loadBarShowTimestamp + delayTimeInMs < Date.now()) {
        let percentage = (this.fromShowingTotalCompletedRunUploadCount / this.fromShowingTotalRunUploadCount) * 100;

        if (this.fromShowingTotalCompletedRunUploadCount === 0 && this.fromShowingTotalRunUploadCount === 0) {
          percentage = 100;
        }
        if (percentage === 100) {
          if (this.showUpload) {
            this.triggerCompleted();
          }
        }
        return percentage;
      }
      if (!this.loadbarDelay ) {
        setTimeout(() => {
          this.recompute += 1;
        }, delayTimeInMs);
        this.loadbarDelay = true;
      }
      return 0;
    },
  },
  methods: {
    storeCompetedRunUploadCountAtThisTime() {
      this.showUploadTrueCompletedRunUploadCount = this.totalCompletedRunUploadCount;
    },
    triggerCompleted() {
      this.loadbarDelay = false;
      setTimeout(() => {
        this.showCompleted = true;
        setTimeout(() => {
          this.hideCompleted = true;
          this.$emit('resetUiLoader');
        }, 3000);
      }, 1500);
    },
  },
});
