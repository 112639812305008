<template>
  <section>
    <div class="feedback">
      <div class="col">
        <div class="left">
          <h2>Something not right?</h2>
          <h3 class="alignleft">Let us know</h3>
          <p>This is a work in progress tool. We welcome feedback as there will most likely be something we've missed</p>
        </div>
        <div class="right">
          <input class="fields name" :class="{'fields-active': name !== ''}" type="text" name="" id="" v-model="name" placeholder="Name">
          <textarea class="fields textarea" :class="{'fields-active': message !== ''}" name="" id="" v-model="message" placeholder="Message"></textarea>
          <div class="button-wrap">
            <button class="button">
            <div v-if="message.length === 0 || name.length === 0" class="inactive">
              <p>Send</p>
            </div>
            <div v-else @click="sendMessage">
              <p>Send</p>
            </div>
          </button>
        </div>

        </div>

      </div>
      <transition name="grow">
        <div v-if="messageSuccess" class="banner-message">
          <p>Message sent. Thanks for the feedback!</p>
        </div>
      </transition>
      <transition name="grow">
        <div v-if="messageFail" class="banner-message banner-message-failed">
          <p>Message failed. Please try again.</p>
        </div>
      </transition>

    </div>
  </section>
</template>

<script>
export default {
  name: 'sls-feedback',
  data() {
    return {
      message: '',
      email: '',
      topic: '',
      name: '',

      messageSuccess: false,
      messageFail: false,
    };
  },
  methods: {
    sendMessage() {
      console.log(this.message);

      var vue = this
      fetch('https://hooks.slack.com/services/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', {
        method: 'post',
        // headers: {
        //     "Content-Type": "json"
        // },
        body: JSON.stringify(
          { "text": `Feedback from *${this.name}* - ${this.email}`,
            "attachments": [
              { "text": this.topic },
              { "text": this.message },
            ],
          }),
        })
        .then(function(){
          vue.name = '';
          vue.message = '';
          vue.messageSuccessAni();
        })
        .catch((error) => {
          vue.messageFailAni();
          // console.log(error);
        });
    },
    messageSuccessAni(){
      this.messageSuccess = true;
      setTimeout(() => {
        this.messageSuccess = false;
      }, 2500)
    },
    messageFailAni(){
      this.messageFail = true;
      setTimeout(() => {
        this.messageFail = false;
      }, 2500)
    }
  }

}
</script>

<style lang="scss" scoped>

.feedback{
  background: rgba(#091c27, 1);
  padding: 50px 50px;
  // border: 4px solid #2a3e44;
  // box-shadow: #c9bf92 0 0 0 4px;
  border: #c9bf92 5px solid;
  border-width: 5px 0 5px 0;
  box-shadow: inset #797356 0 4px 0 0,
              inset rgba(0, 0, 0, 0.3) 0 10px 0 0, 
              #5a5435 0 6px 0 0, 
              rgba(0, 0, 0, 0.10) 0 9px 1px 0;//, ;

}

.col {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  max-width: 900px;
  > div {
    width: 50%;
  }
  > .right {
    padding-left: 40px;
  }
  > .left {
    align-self: flex-start;
  }
}

h2 {
  margin: 40px 0 36px;
  font-size: 32px;
}

.fields {
  width: 100%;
  display: block;
  color: #ede688;
  background: #496d79;
  border: 4px solid #2a3e44;
  box-shadow: #c9bf92 0 0 0 3px;
  transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
  outline: none;
  border-radius: 4px;

  &::placeholder { /* Most modern browsers support this now. */
   color:rgba(#ede688, 0.4);
  }

  &:focus {
    box-shadow: #F5C947 0 0 0 4px;
    transform: scale(1.02);
    transition: 0;
  }
  &-active {
    box-shadow: #F5C947 0 0 0 4px;
    transform: scale(1.02);
    transition: 0;
  }
}
.name {
  margin-top: 20px;
  padding: 10px 18px;
}

.textarea {
  padding: 18px;
  height: 140px;
  margin: 24px auto 12px; 
}

.button {
  display: flex;
  // width: 96%;
  // height: 50px;
  justify-content: center;
  background: none;
  border: none;
  margin: auto;
  outline: none;
  color: #fff6e1;
  > div {
    // background: #000;
    width: 110px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    // background: url('./../assets/ui/buttonL2.png');
    background: url('./../assets/ui/buttonHover2.png');

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);

    display: flex;
    &:hover {
      transform: scale(1.08,1.08);
      transition: 0s;
      z-index: 100;
      background: url('./../assets/ui/buttonHover2.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    > p {
      padding: 0;
      margin: 0 0 2px;
      font-size: 14px;
    }
  }
  .inactive {
    filter: grayscale(100%);
    transform: scale(0.95,0.95);
    color: rgb(187, 187, 187);

    &:hover {
      transform: scale(0.95,0.95);
      // background: url('./../assets/ui/buttonL2.png');
      background: url('./../assets/ui/buttonHover2.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.banner-message {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  color: #FFF6E1;
  box-shadow: #c9bf92 0 0 0 5px, rgba(#000, 0.3) 0 4px 0 7px;
  background: lighten(#3F5F69, 5%);
  border: 5px solid #2a3e44;
  padding: 0 30px;
  border-radius: 10px;
  font-size: 18px;
  z-index: 4000;
  &-failed {
    background: #aa1919;
    border: 5px solid #7c1212;
  }
}

// Animations
// .grow-leave-active {
//   transition: all 0.195s linear;
// }
.grow-leave-active,
.grow-enter-active {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.grow-enter {
  transform: translate(-50%, 240px);
}
.grow-leave-to {
  transform: translate(-50%, 0px);
  // z-index: 2900;
}
</style>

