interface SlackMessage {
  text: string;
  attachments: Array<{
    type: string;
    title: string;
    text: string;
  }>;
}

export default function sendMessage(
  apiURL: string,
  title: string,
  messages: any,
  runData: any,
) {

  // console.log('slack');
  // console.log(messages);

  const jsonMessage: SlackMessage = {
      text: title,
      attachments: [],
  };


  messages.forEach((message: any) => {
    jsonMessage.attachments.push(
      {
        type: 'text',
        title,
        text: message.message,
      },
    );
  });

  jsonMessage.attachments.push({
    type: 'text',
    title: 'Run Data',
    text: JSON.stringify(runData),
  });

  console.log('slackjson');
  console.log(jsonMessage);

  fetch(apiURL, {
    method: 'post',
    // headers: {
    //     'Content-Type': 'json'
    // },
    body: JSON.stringify(jsonMessage),
    });
}
