<template>
    <div v-show="show"
        class="fixed top-0 md:top-auto md:bottom-0 z-50 transform -translate-x-1/2 mt-24 md:mt-0 md:mb-10 p-5 px-8 w-full max-w-4xl box rounded-xl">
        <div class="md:flex justify-between items-center">
            <div>
                <p class="text-xl font-medium">Introducing the new SpireSpy</p>
                <p>We've redesigned SpireSpy to make browsing faster and items better organised</p>
            </div>
            <div class="md:pl-8 pt-6 md:pt-0 border-opacity-25 grid grid-cols-2 w-full md:w-auto text-center">
                <a class="absolute md:relative top-0 right-0 mr-6 md:mr-0 mt-3 md:mt-0 p-3 uppercase text-sm" @click="show = !show">No thanks</a>
                <a class="col-span-2 md:col-span-1 p-3 px-4 uppercase text-sm bg-opacity-10 hover:bg-teal-300 hover:bg-opacity-25 shadow-md bg-white rounded-md"
                    href="/spirespy">Check it out</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'sts-nav',
    data() {
        return {
            show: true,
        }
    }
}
</script>

<style>
.box {
    box-shadow: 0 4px 0 0 #08485a;
    background: #396270;
    left: 50%;
}
</style>