












































































// https://codepen.io/nguernse/pen/JyYdNY

import Vue from 'vue';

import {
  getDataTransferFiles,
  getDroppedOrSelectedFiles,
} from './helpers/fileSelector';
import loadFileAsync from './helpers/asyncFileLoader';
import gql from 'graphql-tag';
import Run from '../../../graphql/RunFragment.gql';

import slackMessage from '../../../helpers/slack';
import uploadUI from './runUploadUI.vue';
import debounce from 'lodash/debounce';
// import nobbetamodal from '../components/nobBetaTester.vue';

// import { read } from 'fs';
// import { parse } from 'path';
// import { of } from 'zen-observable';

const charactersArray = [
  // Uppercase in game json
  'IRONCLAD',
  'THE_SILENT',
  'DEFECT',
  'WATCHER',
];

interface RunUpload {
  run: {};
  pending: boolean;
  success: boolean;
  duplicate: boolean;
  error?: string;
}

export default Vue.extend({
  name: 'runUploadDragAndDrop',
  components: {
    uploadUI,
    // nobbetamodal
  },
  props: {
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openModal: false as boolean,
      allFilesToProcess: [] as File[],
      allFilesProcessedNames: [] as string[], // for duplicate upload server saving
      runFileCount: 0 as number,
      filesAccepted: 0 as number,
      filesRejected: 0 as number,
      runsToUpload: [] as Array<{}>,
      runUploads: [] as RunUpload[],
      keyCounts: {} as any,
      t0: 0,
      t1: 0,

      // UI data
      uiShowFileScan: false,
      uiShowUpload: false,
      uiShowNoUpload: false,
      uiTotalRunUploadCount: 0,
      uiTotalCompletedRunUploadCount: 0,
      uiTotalCompletedSnapshot: 0,

      timeStartUpload: 0,
      timeShowUpload: 0,

      // nobTester: false,
    };
  },
  computed: {
    numberOfCompletedUploads(): number {
      return 0;
    },
    timeElapsedPercent(): number {
      return this.timeShowUpload - this.timeStartUpload;
    },
    // activateUploadUi(): boolean {
    // }
    // totalNumberOfUploads(): number {
    //   return
    // }

    // timestamp now - timestampat start of first upload
  },
  methods: {
    // inverseLerp(a: number, b: number, l: number) {
    //   return (l - a) / (b - a);
    // },
    uiShowUploadDebouncer: debounce(function(this: any) {
      this.uiTotalCompletedSnapshot = this.uiTotalCompletedRunUploadCount;
      this.uiShowUpload = true;
    }, 400),
    async uploadRun(run: {}) {
      // if (!this.uiShowUpload) {
      this.uiTotalRunUploadCount += 1;
      this.uiShowUploadDebouncer();
      const runData = run;
      // }
      try {
        // We save the user input in case of an error
        // const runData = run;
        // We clear it early to give the UI a snappy feel
        // this.newTag = ''
        // Call to the graphql mutation
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation($run: CreateRunInput!) {
              spirespyCreateRun(run: $run) {
                ...Run
              }
            }
            ${Run}
          `,
          // Parameters
          variables: {
            run,
          },
          // Update the cache with the result
          // The query will be updated with the optimistic response
          // and then with the real result of the mutation
          // update: (store, { data: { addTag } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({ query: TAGS_QUERY })
          //   // Add our tag from the mutation to the end
          //   data.tags.push(addTag)
          //   // Write our data back to the cache.
          //   store.writeQuery({ query: TAGS_QUERY, data })
          // },
          // Optimistic UI
          // Will be treated as a 'fake' result as soon as the request is made
          // so that the UI can react quickly and the user be happy
          // optimisticResponse: {
          //   __typename: 'Mutation',
          //   addTag: {
          //     __typename: 'Tag',
          //     id: -1,
          //     label: newTag,
          //   },
          // },
        });
        this.runUploads.push({
          run: result,
          pending: false,
          success: true,
          duplicate: false,
        });
        this.uiTotalCompletedRunUploadCount += 1;

        // .then((data) => {
        //   // Result
        //   console.log(data);
        //   this.runUploads.push({
        //     run: data,
        //     pending: false,
        //     success: true,
        //     duplicate: false,
        //   })
        // }).catch((error) => {
        //   // Error
        //   console.error(error);
        //   // We restore the initial user input
        //   // this.newTag = newTag
        // })
      } catch (error) {
        this.runUploads.push({
          run: '',
          pending: false,
          success: false,
          duplicate: false,
        });
        slackMessage(
          'https://hooks.slack.com/services/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',
          'GraphQL upload error',
          error.networkError.result.errors,
          runData,
        );
        this.uiTotalCompletedRunUploadCount += 1;
      }
    },
    async processFiles() {
      // this.uiShowFileScan = true;
      this.timeStartUpload = Date.now();
      // Check valid run file and load
      console.log(this.allFilesToProcess.length);

      for (const file of this.allFilesToProcess) {
        await this.validateFileAndLoadData(file);
      }
      // Load file data into array
      this.t1 = performance.now();
      // alert('Scanning took: ' + (this.t1 - this.t0) + ' milliseconds.');
      // alert(`---- Completed in ${this.scanTime} ----`);
      console.log(`Run Data:`, this.runsToUpload.length, this.runsToUpload);
    },

    // Data Validation
    validateRunData(run: any, name: string) {
      // console.log(`Validating run ${run.timestamp}`);
      // const highlightDate = this.dateStringToTimestamp('2018-03-23v3');
      // ironclad 2018-03-15v3 onwards  (march 23rd 2018)
      // ironclad 2018-03-22 onwards

      const ironcladDate = this.dateStringToTimestamp('2019-01-23');
      const silentDate = this.dateStringToTimestamp('2019-01-23');
      const defectDate = this.dateStringToTimestamp('2019-01-23');
      const watcherDate = this.dateStringToTimestamp('2020-01-14');

      this.runKeyCounter(run);
      if (
        this.runDataKeyExists('build_version', run) &&
        this.runDataKeyExists('character_chosen', run) &&
        this.runDataKeyExists('is_daily', run) &&
        this.runDataKeyExists('play_id', run) &&
        this.runDataKeyExists('playtime', run) &&
        this.runDataKeyExists('seed_played', run) &&
        this.runDataKeyExists('timestamp', run, name) &&
        this.runDataKeyExists('victory', run, name) &&
        this.excludeRunIfKeyValue(run.is_daily, true, name) &&
        this.isTimeStampCorrect(run.timestamp, run.timestamp.toString()) && // need the name as a string. :(
        this.validDataKeyValue(
          run.timestamp,
          this.timestampNameStringToNumber(name),
        )
        // this.validDataKeyValues(run.character_chosen, charactersArray, 'or') &&
        // this.validDateAfterCharacterReleaseDate('IRONCLAD', run.character_chosen, ironcladDate, run.timestamp ) &&
        // this.validDateAfterCharacterReleaseDate('THE_SILENT', run.character_chosen, silentDate, run.timestamp ) &&
        // this.validDateAfterCharacterReleaseDate('DEFECT', run.character_chosen, defectDate, run.timestamp ) &&
        // this.validDateAfterCharacterReleaseDate('WATCHER', run.character_chosen, watcherDate, run.timestamp )
        // this.runDataKeyExists('score', run) &&
      ) {
        this.uploadRun(run);
        return;
      }
    },
    validDateAfterCharacterReleaseDate(
      character: string,
      runCharacter: string,
      minTimestamp: number,
      runTimestamp: number,
    ) {
      if (character === runCharacter && runTimestamp < minTimestamp) {
        return false;
      }
      return true;
    },
    runKeyCounter(run: any) {
      const allKeys = Object.keys(run);
      allKeys.forEach((key) => {
        if (key === 'is_prod' && run.is_prod === false) {
          if ('is_prod_false' in this.keyCounts) {
            this.keyCounts.is_prod_false += 1;
          } else {
            this.keyCounts.is_prod_false = 1;
          }
        }
        if (key === 'is_daily' && run.is_daily === true) {
          if ('is_daily_true' in this.keyCounts) {
            this.keyCounts.is_daily_true += 1;
          } else {
            this.keyCounts.is_daily_true = 1;
          }
        }
        if (key in this.keyCounts) {
          this.keyCounts[key] += 1;
        } else {
          this.keyCounts[key] = 1;
        }
      });
    },
    objectKeyCount(obj: {}): number {
      return Object.keys(obj).length;
    },
    excludeRunIfKeyValue(runKeyValue: any, value: any, name: string) {
      if (runKeyValue !== value) {
        return true;
      }
      console.log(`${name} is a daily run, skipping. 😁`);
      return false;
    },
    runDataKeyExists(key: string, run: any, name?: string): boolean {
      if (key in run) {
        // console.log(key, 'exist in run');
        return true;
      }
      if (key === 'timestamp') {
        run.timestamp = this.timestampNameStringToNumber(name!);
        return true;
      }
      console.log(key, 'doesnt exist in run');
      return false;
    },
    validDataKeyValue(runKeyValue: any, value: any): boolean {
      if (runKeyValue === value) {
        // console.log('values match');
        return true;
      }
      // console.log('values dont match');
      return false;
    },
    validDataKeyValues(
      runKeyValue: any,
      valuesToCheckArray: any[],
      operator: string,
    ): boolean {
      // operator = 'or' or 'and'
      let result = false;
      if (operator === 'and') {
        // console.log('and');
        // for (const value of valuesToCheck) {
        //   if (value !== runKeyValue) {
        //     return false;
        //   }
        // }
        // return true;
      } else {
        for (const value of valuesToCheckArray) {
          // console.log('or');
          // console.log(value);
          // console.log(runKeyValue);

          if (value === runKeyValue) {
            result = true;
            // console.log('Values Check', result);
          }
        }
      }
      // console.log('Values Check', result);
      if (!result) {
        console.log(
          `We don't currently support '${runKeyValue.toLowerCase()}' character, skipping. 😉`,
        );
      }
      return result;
    },

    // File Validation
    async validateFileAndLoadData(file: File) {
      try {
        if (
          this.fileHasNoType(file.type, file.name) &&
          this.isRunFile(file.name) &&
          this.isTimeStampCorrect(
            this.timestampNameStringToNumber(file.name),
            this.getNameWithoutDotRun(file.name),
          )
        ) {
          // if the user is logged in ignore duplicate uploads catcher on the frontend
          // so that users who upload then login can do it again to own the runs
          if (this.sessionStorageLoad('username') === null) {
            if (!this.isNotDuplicateFile(file.name)) {
              return;
            }
          }
          this.filesAccepted += 1;
          const fileData = await loadFileAsync(file.fileObject);
          this.validateRunData(fileData, file.name);
          return;
        }
        this.filesRejected += 1;
      } catch (error) {
        console.log('Error:', error);
      }
    },
    timestampNameStringToNumber(name: string): number {
      const length = name.length;
      const stringOfName = name.substring(0, length - 4);
      const runTimestamp = parseInt(stringOfName, 10);
      return runTimestamp;
    },
    dateStringToTimestamp(date: string): number {
      const dateSubString = date.substring(0, 9);
      return Math.round(new Date(dateSubString).getTime() / 1000);
    },
    isTimeStampCorrect(timestamp: number, name: string): boolean {
      const runTimestamp = timestamp;
      const earlyAccessReleaseDateTimestamp = this.dateStringToTimestamp(
        '2017-11-14',
      );
      // const highlightDate = this.dateStringToTimestamp('2018-03-23v3');
      const officialReleaseDateTimestamp = this.dateStringToTimestamp(
        '2019-01-23',
      );
      const currentTime = Date.now();

      // is timestamp a number, and are the string lengths equal
      if (
        isNaN(runTimestamp) ||
        runTimestamp.toString().length !== name.length
      ) {
        console.log(
          `Someone, or something... has tampered with the name of ${name} 😭. Best to just drag and drop straight from your run folder. 😉 [NAVTSFN]`,
        );
        return false;
      }
      // is timestamp after release date and before current time
      if (runTimestamp < officialReleaseDateTimestamp) {
        console.log(
          `${name} is from a version thats too old, skipping. 😞 [NAVTSAR]`,
        );
        return false;
      }
      // is timestamp after release date and before current time
      if (runTimestamp > currentTime) {
        console.log(
          `Something strange has occured with ${name}. It appears to be invalid, skipping. 😞 [NAVTSIF]`,
        );
        return false;
      }
      return true;
    },
    getNameWithoutDotRun(name: string): string {
      const length = name.length;
      const stringName = name.substring(0, length - 4);
      return stringName;
    },
    isRunFile(name: string): boolean {
      const length = name.length;
      const stringResult = name.substring(length - 4, length);
      if (stringResult !== '.run') {
        console.log(`${name} is not a '.run' file, skipping. 🙈 [NAVDRF]`);
        return false;
      }
      this.runFileCount += 1;
      // this.isValidNobTester();
      return true;
    },
    fileHasNoType(fileType: string, name: string): boolean {
      // check file type is 'slaythespire/run'
      if (fileType !== 'slaythespire/run') {
        console.log(
          `${name} is not a '.run' file, skipping. 🙈 ${name} [NAVFT]`,
        );
        return false;
      }
      return true;
    },
    isNotDuplicateFile(fileName: string) {
      if (this.allFilesProcessedNames.find((el) => el === fileName)) {
        console.log(
          `${fileName} has already uploaded. Thanks for being thorough! 🙌 [FVDFU]`,
        );
        return false;
      }
      this.allFilesProcessedNames.push(fileName);
      return true;
    },
    resetUiLoader() {
      this.uiShowFileScan = false;
      this.uiShowUpload = false;
      // this.isValidNobTester();
    },
    // isValidNobTester() {
    //   if (this.runFileCount > 199) {
    //     this.nobTester = true;
    //   }
    // },
    // closeNobTesterModal() {
    //   this.nobTester = false;
    // },
    // getCookie(cookieName) {
    //   if (document.cookie.length > 0) {
    //     let cookieStart = document.cookie.indexOf(cookieName + "=");
    //     if (cookieStart !== -1) {
    //         cookieStart = cookieStart + cookieName.length + 1;
    //         let cookieEnd = document.cookie.indexOf(";", cookieStart);
    //         if (cookieEnd == -1) {
    //             cookieEnd = document.cookie.length;
    //         }
    //         return unescape(document.cookie.substring(cookieStart, cookieEnd));
    //     }
    // }
    sessionStorageLoad(keyName: string) {
      // console.log(JSON.parse(localStorage.getItem(name)));
      return sessionStorage.getItem(keyName);
    },

    // checkHasNobKey() {
    //   if (localStorage.getItem('nobBetaTesterKey') !== null) {
    //     return true;
    //   }
    //   return false;
    // }
  },
  mounted() {
    // check to see if has a key and show
    // this.checkHasNobKey();

    const self = this;

    const dropzoneElement = document.querySelector<HTMLDivElement>('#dropzone');
    window.addEventListener('dragenter', (e) => {
      // @ts-ignore
      document.querySelector('#dropzone').style.visibility = '';
      // @ts-ignore
      document.querySelector('#dropzone').style.opacity = 1;
      // document.querySelector('#textnode').style.fontSize = '48px';
    });

    window.addEventListener('dragleave', (e) => {
      e.preventDefault();
      // @ts-ignore
      document.querySelector('#dropzone')!.style.visibility = 'hidden';
      // @ts-ignore
      document.querySelector('#dropzone')!.style.opacity = 0;
      // document.querySelector('#textnode').style.fontSize = '42px';
    });

    window.addEventListener('dragover', (e) => {
      e.preventDefault();
      // @ts-ignore
      document.querySelector('#dropzone')!.style.visibility = '';
      // @ts-ignore
      document.querySelector('#dropzone')!.style.opacity = 1;
      // document.querySelector('#textnode').style.fontSize = '48px';
    });

    window.addEventListener('drop', async (e: any = []) => {
      e.preventDefault();
      // @ts-ignore
      document.querySelector('#dropzone')!.style.visibility = 'hidden';
      // @ts-ignore
      document.querySelector('#dropzone')!.style.opacity = 0;
      // document.querySelector('#textnode').style.fontSize = '42px';
      // let files: any = [];
      // const files2 = e.dataTransfer.files;
      this.t0 = performance.now();
      // this.uiShowFileScan = false;
      // this.uiShowUpload = false;
      this.uiShowFileScan = true;
      const files: File[] = await getDroppedOrSelectedFiles(e);
      // console.log(files);
      // console.log(files2);
      self.allFilesToProcess = files;
      // console.log('Drop files:', files);
      this.processFiles();
      setTimeout(() => {
        if (this.uiShowUpload === false) {
          this.uiShowNoUpload = true;
          this.resetUiLoader();
        }
        setTimeout(() => {
          this.uiShowNoUpload = false;
        }, 2000);
      }, 1500);
    });
  },
});
