<template>
  <div class="sls">
    <navbar />
    <dragdrop :isPage="isPage" />
    <SpyspireVersion2Banner/>
    <!-- <browse></browse> -->
    <router-view name="hero" />
    <router-view />
    <feedback />
    <footerbar />

    <!-- Preload images for new game -->
    <!-- <img v-show="false" src="./assets/backgrounds/ironcladPortrait.jpg" alt=""> -->
    <!-- <img v-show="false" src="./assets/backgrounds/silentPortrait.jpg" alt=""> -->
  </div>
</template>

<script>
import navbar from './components/nav';
// import browse from './components/browse';
import feedback from './components/feedback';
import dragdrop from './components/dragdropfullscreen';
import footerbar from './components/footer';
import SpyspireVersion2Banner from './components/spyspireVersion2Banner.vue';
 

export default {
  name: 'sts-home',
  components: { navbar, feedback, dragdrop, footerbar, SpyspireVersion2Banner },
  data() {
    return {
      test: false,
      img: './assets/metadata/main.png',
    };
  },

  // filtering
  // mounted: function(){
  //   (function(d) {
  //     var config = {
  //       kitId: 'kpj2pou',
  //       scriptTimeout: 3000,
  //       async: true
  //     },
  //     h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  //   })(document);
  // },
  computed: {
    filteredList() {
      return this.postList.filter((post) =>
        post.title.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
// @import url("https://use.typekit.net/kpj2pou.css");
@import url('https://fonts.googleapis.com/css?family=Kreon:300,400&display=swap');

.sls {
  min-height: 100vh;
  // background: #03053b;
  color: #fff6e1;
  font-family: 'Kreon', serif;
  font-weight: 300;
  // background-image:
  //   // linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
  //   url('./assets/backgrounds/foregroundBG.png'),
  //   url('./assets/backgrounds/backgroundBG.jpg');
  // background-size: cover;
  // background-position: center;
  // background-attachment: fixed;
  // background: #10212B;

  padding: 0 0 1px 0;

  // overflow: hidden; // added for pseudo-element
  position: relative; // added for pseudo-element
  cursor: url('./assets/cursor/mouse.png'), auto !important;
  cursor: url('./assets/cursor/mouse.png'), pointer !important;
  cursor: url('./assets/cursor/mouse.png'), text !important;

  &::before {
    content: ' ';
    position: fixed; // instead of background-attachment
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #10212b;
    background-image:
      // linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('./assets/backgrounds/foregroundBG.png'),
      url('./assets/backgrounds/backgroundBG.jpg');
    background-position: center;
    background-size: cover;
    will-change: transform; // creates a new paint layer
    z-index: -1;
  }

  h1 {
    font-size: 3.6em;
    font-weight: 500;
    line-height: 1.2em;
    text-align: center;
    text-shadow: 4px 4px rgba(0, 0, 0, 0.6);
  }

  h2 {
    font-size: 2.5em;
  }
}
</style>

<style lang="scss">
// .sls {
//   min-height: 100vh;
//   // background: #03053b;
//   color: #FFF6E1;
//   font-family: 'Kreon', serif;
//   background: #10212B;
//   background-image:
//     linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
//     url('./assets/backgrounds/foregroundBG.png'),
//     url('./assets/backgrounds/backgroundBG.jpg');
//   background-size: cover;
//   background-position: center;
//   background-attachment: fixed;
//   padding: 0 0 0 0;
// }

a {
  color: #fff;
  text-decoration: none;
}

.scroll-lock {
  overflow: hidden !important;
  // max-height: 100vh;
}
</style>
