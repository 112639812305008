export default function loadFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result;
      const jsonResult = typeof result === 'string' ? JSON.parse(result) : '';
      if (jsonResult === '') {
        reject();
      }
      resolve(jsonResult);
    };
    reader.onerror = () => reject();
    reader.readAsText(file);
  });
}

// old version working

    // loadFile(file: File) {
    //   return new Promise((resolve, reject) => {
    //     // let jsonData;
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       if (e !== null) {
    //         console.log(`Error loading run data. ${file.name} appears to be corrupt. 😭 [FVLFE]`);
    //         return;
    //       }
    //       const content: string = e.target.result;
    //       return JSON.parse(content);
    //       // console.log(jsonData);
    //       // this.runDataArray.push(jsonData);
    //       // console.log(this.runDataArray.length);
    //       resolve(content);

    //     };
    //     // console.log(reader);
    //     // console.log(await reader);
    //     // this.validateRunData(reader.result);

    //     // reader.onerror = reject;
    //     // reader.readAsText(file, 'UTF-8');
    //   });
    // },
