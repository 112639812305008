
































































































































import Vue from 'vue';
import gql from 'graphql-tag';
import loading from '../../../components/loading.vue';
export default Vue.extend({
  name: 'TheFooter',
  components: { loading },
  data() {
    return {
      spirespyGetRun: {},
      readmore: false,
      test: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras at arcu id augue sagittis cursus eu luctus dolor. Sed lacinia blandit nunc, eget scelerisque arcu pharetra a. Aliquam erat volutpat. Curabitur bibendum non purus id pellentesque. Sed massa lacus, sollicitudin in arcu at, bibendum pulvinar metus. Sed laoreet erat a nisi commodo, sit amet posuere velit tincidunt. Sed aliquet odio eu enim blandit, eu ultrices ipsum sagittis. Mauris eget lacinia justo. Fusce rutrum, ante sit amet aliquet maximus, massa lorem pulvinar mauris, quis maximus lectus enim sit amet libero. Etiam odio diam, tincidunt in tristique ut, ultricies in leo.str.substring(1, 4)str.substring(1, 4)str.substring(1, 4)str.substring(1, 4) str.substring(1, 4) str.substring(1, 4) str.substring(1, 4) str.substring(1, 4) str.substring(1, 4) str.substring(1, 4)',
      spirespyGetLatestXComments: {} as { body: string, name: string },

      // Comments
      commentError: false as boolean,
    };
  },
  apollo: {
    // Query with parameters
    spirespyGetLatestXComments: {
      // gql query
      query: gql`query($limit: Int!) {
        spirespyGetLatestXComments(limit: $limit) {
          body
          name
        }
      }`,
      // Static parameters
      variables() {
        return {
          // @ts-ignore
          limit: 1,
        };
      },
      // update: (data: any) => data.spirespyGetLatestXCommentsData,
      // error(error: any) {
      //  console.log('GraphQL Error:', error);
      //  this.commentError = error;
      // }
    } as {},
  },
  mounted() {
    this.checkForCommentError();
  },
  computed: {
    commentBodyMaxLength(): string {
      if (this.spirespyGetLatestXComments.body == null) {
        return '';
      }
      if ( this.spirespyGetLatestXComments.body.length < 240) {
        this.readmore = false;
        return this.spirespyGetLatestXComments.body;
      }
      this.readmore = true;
      return this.spirespyGetLatestXComments.body.substring(0, 240);
    },
  },
  methods: {
    checkForCommentError() {
      this.commentError = false;
      setTimeout(() => {
        if (this.spirespyGetLatestXComments === undefined) {
          this.commentError = true;
        }
      }, 3000);
    },
    retryGetComment() {
      this.$apollo.queries.spirespyGetLatestXComments.refetch();
      this.checkForCommentError();
    },
  },
  /// ADD WATCHER FOR ROUTE CHANGE
  watch: {
    $route(to, from) {
      this.retryGetComment();
    },
  },
});
