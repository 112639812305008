<template>
  <div>
    <nav class="navbar navbar-template nav-border" v-show="visible">
      <!-- <div class="container"> -->

      <!-- <div class="hamburger p-1" @click="hamburgerMenuOpen = !hamburgerMenuOpen">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div> -->
      <div
        class="hamburger select-none bg-white bg-opacity-10 rounded-sm ml-4 py-1 uppercase font-medium text-sm block"
        @click="hamburgerMenuOpen = !hamburgerMenuOpen"
      >
        <p class="text-center">menu</p>
      </div>

      <div>
        <router-link :to="{ name: 'sls-home' }" exact>
          <div class="wlp-title flex align-middle justify-center">
            <img
              class="mr-2 w-20 h-12"
              src="../assets/logo/spirespy.svg"
              alt=""
            />
            <!--<p class="m-0 p-0">SpireSpy</p>-->
          </div>
        </router-link>
      </div>
      <!-- <div class="nav-link-styling"> -->
      <div
        class="nav-link-styling links1"
        :class="{ 'nav-link-mobile-hide': !hamburgerMenuOpen }"
      >
        <div>
          <router-link :to="{ name: 'sls-home' }" exact>home</router-link>
        </div>
        <div>
          <router-link :to="{ name: 'sls-devlog' }" exact>devlog</router-link>
        </div>
        <div>
          <router-link :to="{ name: 'sls-tierlist-cards-redirect' }"
            >card tier list</router-link
          >
        </div>
        <div>
          <router-link :to="{ name: 'sls-tierlist-relics-redirect' }"
            >relic tier list</router-link
          >
        </div>
        <div>
          <router-link :to="{ name: 'sls-mods' }"
            >mods</router-link
          >
        </div>
        <div>
          <router-link :to="{ name: 'sls-newgame' }" class="button" exact
            >New Deck</router-link
          >
        </div>
        <div>
          <router-link
            v-if="savedDeck"
            :to="{ name: 'sls-loadout' }"
            class="button"
            exact
            >Resume Deck</router-link
          >
        </div>
        <div>
          <router-link :to="{ name: 'sls-uploadgames' }"
            >upload runs</router-link
          >
        </div>
        <div>
          <a href="/spirespy"
            >spirespy v2<span class="new">new</span></a>
        </div>
      </div>

      <div
        class="nav-link-styling links2 nav-border"
        :class="{ 'nav-link-mobile-hide': !hamburgerMenuOpen }"
      >
        <!-- <div>
            <router-link :to="{ name: 'sls-newgame' }" class="button" exact>New Deck</router-link>
          </div>
          <div>
            <router-link v-if="savedDeck" :to="{ name: 'sls-loadout' }" class="button" exact>Resume Deck</router-link>
          </div> -->
        <transition name="fade">
          <div v-if="apiResponse && onlineShowTimeout" class="online">
            <p class="button">{{ userOnlineCount }} Online</p>
          </div>
        </transition>
        <transition name="fade" mode="in-out">
          <div v-if="role === 'admin' || role === 'superadmin'">
            <router-link class="user-profile" :to="{ name: 'sls-admin-home' }"
              >dashboard</router-link
            >
          </div>
        </transition>

        <transition name="fade" mode="in-out">
          <div v-if="username">
            <router-link
              class="user-profile"
              :to="{ name: 'sls-user-profile', params: { username } }"
              >{{ username }}</router-link
            >
          </div>
        </transition>
        <transition name="fade" mode="in-out">
          <div v-if="username">
            <a @click.prevent="logout">logout</a>
          </div>
        </transition>
        <transition name="fade" mode="in-out">
          <div v-if="loginChecked && !username">
            <router-link :to="{ name: 'sls-login' }">login</router-link>
          </div>
        </transition>
        <transition name="fade" mode="in-out">
          <div v-if="loginChecked && !username">
            <router-link :to="{ name: 'sls-signup' }">signup</router-link>
          </div>
        </transition>
      </div>
      <!-- </div> -->
      <!-- </div> -->
    </nav>
    <div class="nav-padding"></div>
  </div>
</template>


<!-- <span class="new">new</span>  --- for new nav icon -->
<script>
import gql from 'graphql-tag';

export default {
  name: 'sts-nav',

  data() {
    return {
      delay: 300,
      onlineInterval: 30000,
      visible: true,
      hamburgerMenuOpen: false,
      prevHeight: 0,
      userOnlineCount: 0,
      onlineUUID: '',
      apiResponse: false,
      onlineShowTimeout: false,
      username: null,
      loginChecked: false,
      activeState: true,
      role: null,
    };
  },
  onIdle() {
    (this.activeState = false), console.log('idle');
  },
  onActive() {
    (this.activeState = true), console.log('active');
  },
  computed: {
    savedDeck() {
      if (
        localStorage.getItem('mycards') !== null &&
        localStorage.getItem('myRelics') !== null
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    $route() {
      this.hamburgerMenuOpen = false;
      this.getUserInfo();
    },
  },
  methods: {
    getUserInfo() {
      // am I logged in?
      if (this.amILoggedIn() === false) {
        console.log('not logged in');
        return;
      }
      // console.log('logged in');
      // is username already defined?
      // if (this.username === null) {
      // if no, is the username in the storage
      // this.checkForDataInSessionStorage();
      // if (this.username === null) {
      // if no, get the my data
      this.getMeData();
      // }
      // }
    },
    getCookie(cookieName) {
      if (document.cookie.length > 0) {
        let cookieStart = document.cookie.indexOf(cookieName + '=');
        if (cookieStart !== -1) {
          cookieStart = cookieStart + cookieName.length + 1;
          let cookieEnd = document.cookie.indexOf(';', cookieStart);
          if (cookieEnd == -1) {
            cookieEnd = document.cookie.length;
          }
          return unescape(document.cookie.substring(cookieStart, cookieEnd));
        }
      }
      return null;
    },
    checkForDataInSessionStorage() {
      const username = this.sessionStorageLoad('username');
      // sessionstorage returns a string 'undefined' not a undefined value!
      if (username !== 'undefined') {
        console.log('session storage: ' + username);
        this.username = username;
      }
    },
    amILoggedIn() {
      if (this.getCookie('loggedin') === null) {
        return false;
      }
      return true;
    },
    // transistions
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    async getOnlineCount() {
      try {
        if (this.onlineUUID === '') {
          this.createOrLoadUUID();
        }
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation($uuid: String!, $time: Int!, $active: Boolean!) {
              spirespyCreateOnline(uuid: $uuid, time: $time, active: $active)
            }
          `,
          // Parameters
          variables: {
            uuid: this.onlineUUID,
            time: this.onlineInterval / 1000, // for seconds
            active: this.activeState,
          },
        });
        this.userOnlineCount = result.data.spirespyCreateOnline;
        this.apiResponse = true;
      } catch (error) {
        console.log('Cannot get users online');
        console.log(error);
      }
    },
    // -- end transistions
    localStorageSave(name) {
      localStorage.removeItem(name);
      localStorage.setItem(name, JSON.stringify(this[name]));
    },
    localStorageLoad(name) {
      //console.log(JSON.parse(localStorage.getItem(name)));
      return JSON.parse(localStorage.getItem(name));
    },
    sessionStorageSave(keyName, value, isObj) {
      sessionStorage.removeItem(keyName);
      sessionStorage.setItem(keyName, this[value]);
    },
    sessionStorageLoad(keyName) {
      //console.log(JSON.parse(localStorage.getItem(name)));
      return sessionStorage.getItem(keyName);
    },
    async getMeData() {
      try {
        const res = await this.$apollo.query({
          query: gql`
            query {
              me {
                username
                role
              }
            }
          `,
        });
        this.username = res.data.me.username;
        this.role = res.data.me.role;
        console.log(res.data.me.role, this.role);
      } catch (error) {
        console.log(error);
      }
    },
    createOrLoadUUID() {
      if (this.localStorageLoad('onlineUUID')) {
        // console.log('1');
        this.onlineUUID = this.localStorageLoad('onlineUUID');
      } else {
        // console.log('2');
        this.onlineUUID = [...Array(50)]
          .map(() => Math.random().toString(36)[2])
          .join('');
        // console.log(this.onlineUUID);
        this.localStorageSave('onlineUUID');
      }
    },
    async logout() {
      try {
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation {
              logout {
                success
              }
            }
          `,
        });
        // console.log(res.data.success);
        sessionStorage.removeItem('username');
        history.go(0);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getUserInfo();
    this.createOrLoadUUID();
    this.getOnlineCount();
    setInterval(() => {
      this.getOnlineCount();
    }, this.onlineInterval);
    setTimeout(() => {
      this.loginChecked = true;
      this.onlineShowTimeout = true;
    }, 600);
  },
};
</script>

<style lang="scss" scoped>
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

.navbar {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  position: relative;
  top: 0;
  z-index: 1000;
  @include for-tablet-landscape-up {
    position: fixed;
  }

  height: 60px;
  width: 100%;

  // padding: 0 24px 0;
  background: #405e68;
  // margin: 0 0 -78px;
}

.nav-padding {
  width: 100%;
  @include for-tablet-landscape-up {
    height: 60px;
  }
}

.nav-border {
  // border-bottom: 2px solid #2a3e44;
  box-shadow: rgba(#c9bf92, 1) 0 3px 0 0, rgba(#868061, 1) 0 5px 0 0,
    rgba(#000000, 0.3) 0 9px 2px 0;
  box-shadow: rgba(#0a1c22, 0.3) 0 3px 2px 0, inset rgba(#4f7481, 1) 0 -1px 0 0,
    inset rgba(#4f7481, 0.5) 0 -6px 6px -3px;
}

.navbar-template {
  grid-template:
    'hamburger title' 60px
    'links1 links1'
    'links2 links2'
    / 80px 1fr;
  @media screen and (min-width: 800px) {
    grid-template:
      'title links1 links2' 60px
      / max-content auto max-content;
  }
  // @media screen and (min-width: 1000px){
  //   grid-template:
  //     "links1 title links2" 60px
  //     / 1fr auto 1fr;
  // }
}

.wlp-title {
  font-size: 22px;
  font-weight: bold;
  margin: 0 80px 0 0;
  text-align: center;
  grid-area: title;
  @media screen and (min-width: 800px) {
    margin: 0;
    padding: 16px 20px 16px 24px;
  }
}

// This needs to be before nav styling so its overwritten when 820px plus
.nav-link-mobile-hide {
  display: none;
}

.nav-link-styling {
  @media screen and (min-width: 800px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  div {
    a {
      position: relative;

      display: block;
      padding: 16px 24px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      @media screen and (min-width: 800px) and (max-width: 920px) {
        font-size: 13px;
      }

      @media screen and (min-width: 920px) {
        font-size: 14px;
      }

      // @media screen and (min-width: 1000px) and ( max-width: 1021px){
      //   font-size: 11px;
      // }

      // @media screen and (min-width: 1021px) and ( max-width: 1080px){
      //   font-size: 12px;
      // }

      // @media screen and (min-width: 1080px) and ( max-width: 1100px){
      //   font-size: 13px;
      // }
      // @media screen and (min-width: 1100px) and ( max-width: 1180px){
      //   font-size: 14px;
      // }

      @media screen and (min-width: 800px) {
        padding: 4px 9px;
        margin: 0 2px;
        border-radius: 8px;
        border: 1px solid #405e68;
        transition: 0.3s all ease-in-out;
        transform: translateY(1px);
        &:hover {
          background: #4b6f7a;
          border: 1px solid #4b6f7a;
        }
      }
    }

    .router-link-active {
      background: #2a3e44;

      @media screen and (min-width: 800px) {
        background: none;
        position: relative;

        background: #4b6f7a;
        box-shadow: inset rgba(#41636d, 1) 0 -2px 0 0,
          rgba(#868061, 1) 0px 3px 0 -1px, rgba(#000000, 0.3) 0 5px 0 -1px; //inset rgba(#868061, 1) 2px 2px 0 0, inset hsl(196, 88%, 20%) 3px 2px 1px 0,
        border: 1px solid #c9bf92;
        transform: translateY(-1px);
        // padding: 8px 20px;
        &:hover {
          background: #4b6f7a;
          border: 1px solid #c9bf92;
        }
      }
    }
  }
}

.links1 {
  grid-area: links1;
  background: #405e68;
  @media screen and (min-width: 800px) {
    justify-self: start;
    // border-right:rgb(54, 81, 90) 1px solid;
    padding: 0 8px;
  }
  // @media screen and (min-width: 1000px){
  //   justify-self: center;
  //   border-right:none;
  //   padding-right: 0;
  // }
}

.links2 {
  grid-area: links2;
  background: #405e68;
  // border-bottom: 6px solid #2a3e44;
  // box-shadow: rgba(#c9bf92, 1) 0 5px 0 0, rgba(#868061, 1) 0 8px 0 0, rgba(#000000, 0.3) 0 12px 2px 0;
  @media screen and (min-width: 800px) {
    background: none;
    border-bottom: none;
    box-shadow: none;
    margin-right: 14px;
  }
  // @media screen and (min-width: 800px){
  //   justify-self: center;
  // }
}

.hamburger {
  // display: inline-block;
  cursor: pointer;
  grid-area: hamburger;
  // padding: 10px 10px 10px 10px;

  @media screen and (min-width: 800px) {
    display: none;
  }
}

.bar1,
.bar2,
.bar3 {
  width: 32px;
  height: 4px;
  background-color: #fff;
  margin: 6px auto;
  transition: 0.4s;
  border-radius: 10px;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.new {
  position: absolute;
  right: 20%;
  top: 50%;
  transform: translateY(-50%);
  background: #b42020;
  font-size: 10px;
  padding: 1px 3px;
  transform: translate(50%, -50%);
  border-radius: 4px;
  @media screen and (min-width: 800px) {
    top: -5px;
    right: 50%;
  }
}

.online {
  margin-right: 10px;
  font-weight: 500;
}

.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.user-profile {
  // text-transform: capitalize !important;
  background: rgb(49, 75, 83);
  text-transform: none !important;
}
</style>
